import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import { MODALIDADE } from '../../common/Constants';
import {
    SttLoading,
    SttTranslate,
    SttAlerta
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { user, strings, ...other } = props;
    const classes = useStyles();

    const [gerandoPdf, setGerandoPdf] = useState(false);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;

    const imprimir = (idExame, dataHoraAcesso) => {
        setGerandoPdf(true);
        const idCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: { 
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao
            }
        };
        axios
            .get(`${EXAME_API_BASE_URL}/exame/${idCodificado}/imprimir`, opcoes)
            .then((response) => {
                setGerandoPdf(false);
                
                // Atualiza registro de acesso ao exame - IT
                axios
                    .put(`${DERMATO_API_BASE_URL}/registrar-impressao-exame-it`, { idExame, dataHoraAcesso }, { headers: getHeaders() })
                    .catch(err => console.log(err));
                    
                    // Abre o PDF
                    window.open(response.data);
            })
            .catch(err => {
                setGerandoPdf(false);
                console.log(err);
            });

    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        const idExameCodificado = Buffer.from(exame.id_exame?.toString() || exame.id?.toString()).toString('base64');
        const params = { 
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios
            .get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                setGerandoPdf(false);
                window.open(response.data);
            })
            .catch(err => {
                setGerandoPdf(false);
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemErro(strings.erroDesconhecido);
                setErro(true);
            });
    }

    const imprimirTermo = (exame) => {
        setGerandoPdf(true);
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        let url = `${DERMATO_API_BASE_URL}/termo-autorizacao/${Buffer.from(exame.id_paciente.toString()).toString('base64')}/uf/${exame.sigla_uf}`;
        axios
            .get(url, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                setGerandoPdf(false);
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                setGerandoPdf(false);
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemErro(strings.erroGerarTermo);
                setErro(true);
            });

    }

    const handleCloseAlerta = () => {
        setErro(false);
    }

    const opcoesAlerta = [{
        title: strings.ok,
        onClick: handleCloseAlerta
    }];

    return (
        <>
            <Switch>
                <Route path="/exames/visualizar/:id?" children={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" children={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Switch>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>

            <SttAlerta
                open={erro}
                title={tituloAlerta}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('Exame')(Aba));