import React, { useState, useEffect, useRef, memo, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { logoutDispatch as logoutAction } from '../../reducers/actions';
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import { TIPO_DERMATOSE, TIPO_USUARIO, PERMISSOES } from '../../common/Constants';

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import { MEDICO_SOLICITANTE, INSTITUICAO_SOLICITANTE } from '../../componentes/solicitacao/solicitante/fieldNames';
import { FIELDS } from '../../componentes/solicitacao/informacoes-clinicas/form/fieldNames';
import { LESOES } from '../../componentes/solicitacao/lesoes/fieldNames';
import { HMP_CANCER_PELE, HMP_CANCER_OUTRO } from '../../componentes/solicitacao/protocolo-encaminhamento/fieldNames';

import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import EnvioImagens from '../../componentes/envio-imagens/index.js'
import { temPermissaoRBAC } from '../../secutity/acl';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttAlerta,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslate
} from '@stt-componentes/core';

import ProtocoloEncaminhamento from '../../componentes/solicitacao/protocolo-encaminhamento';
import { initialValues } from './initialValues'; 
import { makeStyles } from '@material-ui/core/styles';

const Paciente = lazy(() => import('@stt-componentes/paciente'));
const Solicitante = lazy(() => import('../../componentes/solicitacao/solicitante'));
const InformacoesClinicas = lazy(() => import('../../componentes/solicitacao/informacoes-clinicas'));
const InformacoesComplementares = lazy(() => import('../../componentes/solicitacao/informacoes-complementares'));
const Lesoes = lazy(() => import('../../componentes/solicitacao/lesoes'));
const SttCid10 = lazy(() => import('@stt-componentes/cid10'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    }
}));

const campos = {
    camposBusca: { 
        cpf: { obrigatorio: false }, 
        cns: { obrigatorio: false }
    },
    camposCadastro: [
        [
            { nome: 'nome', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'genero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'dataNascimento', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
        ],
        [
            { nome: 'cpf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cns', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cep', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
        ],
        [
            { nome: 'logradouro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 7, md: 7 } },
            { nome: 'bairro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 5, md: 5 } },
        ],
        [
            { nome: 'pais', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'uf', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'municipio', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'tipoContato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'contato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'profissao', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 8, md: 8 } },
            { nome: 'peso', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'altura', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
        ]
    ]
};

const Solicitacao = (props) => {
    const { strings, user, idSistemaOrigem, idSolicitacaoSistemaOrigem, cpfPadrao, logoutDispatch } = props;
    const { origem } = user;
    const classes = useStyles();
    const history = useHistory();

    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
    const OAUTH_BASE_URL = global.gConfig.url_base_api_oauth2;
    const schema = validationSchema(strings, campos.camposCadastro);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
        abrirSecaoPaciente(true);
        if (origem === TIPO_USUARIO.EXTERNO) {
            logout();
        }
    }

    const handleAbrirModalEnvioImagens = () => {
        setMostrarAlerta(false);
        setMostrarModalEnvioImagens(temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME));
    }

    // Apenas para acesso externo
    const logout = () => {
        axios
            .get(`${OAUTH_BASE_URL}/logout`, { headers: getHeaders() })
            .then(() => {
                localStorage.removeItem('stt-authorization');
            })
            .catch(err => console.log(err))
            .finally(() => {
                logoutDispatch();
                history.replace("/logout");
            });
    };

    const handleCancelarEnvio = () => {
        // Post message
        const mensagem = {
            "codigo": "999"
        }
        window.parent.postMessage(mensagem, '*');

        if (origem === TIPO_USUARIO.EXTERNO) {
            logout();
        }
    }

    //Envio de imagens
    const [enviarImagens, setEnviarImagens] = useState(false);
    const [mostrarModalEnvioImagens, setMostrarModalEnvioImagens] = useState(false);
    const [solicitacoes, setSolicitacoes] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const [secaoPacienteAberta, setSecaoPacienteAberta] = useState(true);
    const [secaoSolicitanteAberta, setSecaoSolicitanteAberta] = useState(false);
    const [secaoInfoClicnicasAberta, setSecaoInfoClicnicasAberta] = useState(false);
    const [secaoInfoComplementaresAberta, setSecaoInfoComplementaresAberta] = useState(false);
    const [secaoLesoesAberta, setSecaoLesoesAberta] = useState(false);
    const [secaoHmpAberta, setSecaoHmpAberta] = useState(false);
    const [secaoCid10Aberta, setSecaoCid10Aberta] = useState(false);

    // Impressão do termo de autorização
    const [confirmarTermo, setConfirmarTermo] = useState(false);
    const [gerarTermo, setGerarTermo] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [instituicaoSolicitante, setInstituicaoSolicitante] = useState(null);

    // Controle do envio de imagens
    const [confirmarEnvioExame, setConfirmarEnvioExame] = useState(false);

    /**
     * Gera o termo de autorização
     * 
     * @param {number} idPaciente
     */
     const imprimirTermo = (idPaciente, instituicaoSolicitante) => {
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        axios
            .get(`${DERMATO_API_BASE_URL}/termo-autorizacao/${btoa(idPaciente)}/uf/${instituicaoSolicitante.uf}`, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroGerarTermo);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setPaciente(null);
                setInstituicaoSolicitante(null);
                setGerarTermo(false);

            });
    }

    useEffect(() => {
        if (gerarTermo) {
            imprimirTermo(paciente, instituicaoSolicitante);
        } else {
            setPaciente(null);
            setInstituicaoSolicitante(null);
        }
    }, [gerarTermo]);

    const abrirSecaoPaciente = (estado) => {
        setSecaoPacienteAberta(estado);
        setSecaoSolicitanteAberta(false);
        setSecaoInfoClicnicasAberta(false);
        setSecaoInfoComplementaresAberta(false);
        setSecaoLesoesAberta(false);
        setSecaoHmpAberta(false);
        setSecaoCid10Aberta(false);
    }
    const abrirSecaoSolicitante = (estado) => {
        setSecaoPacienteAberta(false);
        setSecaoSolicitanteAberta(estado);
        setSecaoInfoClicnicasAberta(false);
        setSecaoInfoComplementaresAberta(false);
        setSecaoLesoesAberta(false);
        setSecaoHmpAberta(false);
        setSecaoCid10Aberta(false);
    }
    const abrirSecaoInfoClinicas = (estado) => {
        setSecaoPacienteAberta(false);
        setSecaoSolicitanteAberta(false);
        setSecaoInfoClicnicasAberta(estado);
        setSecaoInfoComplementaresAberta(false);
        setSecaoLesoesAberta(false);
        setSecaoHmpAberta(false);
        setSecaoCid10Aberta(false);
    }
    const abrirSecaoInfoComplementares = (estado) => {
        setSecaoPacienteAberta(false);
        setSecaoSolicitanteAberta(false);
        setSecaoInfoClicnicasAberta(false);
        setSecaoInfoComplementaresAberta(estado);
        setSecaoLesoesAberta(false);
        setSecaoHmpAberta(false);
        setSecaoCid10Aberta(false);
    }
    const abrirSecaoLesoes = (estado) => {
        setSecaoPacienteAberta(false);
        setSecaoSolicitanteAberta(false);
        setSecaoInfoClicnicasAberta(false);
        setSecaoInfoComplementaresAberta(false);
        setSecaoLesoesAberta(estado);
        setSecaoHmpAberta(false);
        setSecaoCid10Aberta(false);
    }
    const abrirSecaoProtocoloEnc = (estado) => {
        setSecaoPacienteAberta(false);
        setSecaoSolicitanteAberta(false);
        setSecaoInfoClicnicasAberta(false);
        setSecaoInfoComplementaresAberta(false);
        setSecaoLesoesAberta(false);
        setSecaoHmpAberta(estado);
        setSecaoCid10Aberta(false);
    }
    const abrirSecaoCID10 = (estado) => {
        setSecaoPacienteAberta(false);
        setSecaoSolicitanteAberta(false);
        setSecaoInfoClicnicasAberta(false);
        setSecaoInfoComplementaresAberta(false);
        setSecaoLesoesAberta(false);
        setSecaoHmpAberta(false);
        setSecaoCid10Aberta(estado);
    }

    const secaoPaciente = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoInfoClicnicas = useRef(null);
    const secaoLesoes = useRef(null);
    const secaoProtocoloEnc = useRef(null);

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    let dados = { ...data };
                    dados.digitador = {
                        id: user.idFuncionario
                    }
                    if (dados.paciente) {
                        if (dados.paciente.cpf) {
                            dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
                        }
                        if (dados.paciente.cep) {
                            dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
                        }
                    }
                    dados.idSistemaOrigem = null;
                    dados.idSolicitacaoSistemaOrigem = null;
                    if (idSolicitacaoSistemaOrigem) {
                        dados.idSolicitacaoSistemaOrigem = idSolicitacaoSistemaOrigem;
                    }
                    if (idSistemaOrigem) {
                        dados.idSistemaOrigem = idSistemaOrigem;
                    }
                    let tipoAlertaSolicitacao = '';
                    let tituloAlertaSolicitacao = '';
                    let mensagemAlertaSolicitacao = '';

                    axios
                        .post(`${DERMATO_API_BASE_URL}/solicitacao`, dados, { headers: getHeaders() })
                        .then((response) => {
                            const dadosSolicitacao = response.data.data;
                            const dadosPaciente = dadosSolicitacao[0].paciente;
                            setSolicitacoes(dadosSolicitacao);
                            setPaciente(dadosPaciente);
                            setInstituicaoSolicitante(dados.instituicaoSolicitante);
                            tipoAlertaSolicitacao = 'success';
                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: () => {
                                    handleCloseAlerta();
                                    setConfirmarTermo(true);
                                }
                            }]);
                            tituloAlertaSolicitacao = strings.sucesso;
                            mensagemAlertaSolicitacao = response.data.message;
                            resetForm();

                            // Post message
                            let idsGerados = [];
                            dadosSolicitacao.forEach(s => {
                                idsGerados.push(s.id.toString());
                            });
                            const mensagem = {
                                "codigo": "201",
                                "identificadores_solicitacoes": idsGerados
                            }
                            window.parent.postMessage(mensagem, '*');
                        })
                        .catch(err => {
                            const { response } = err;
                            let msg = strings.erroDesconhecido;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const dadosResposta = response.data;
                                    let arrMensagem = [];
                                    dadosResposta.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    tipoAlertaSolicitacao = 'error';
                                    tituloAlertaSolicitacao = dadosResposta.message;
                                    mensagemAlertaSolicitacao = msg;
                                } else {
                                    tipoAlertaSolicitacao = 'error';
                                    tituloAlertaSolicitacao = strings.erro;
                                    mensagemAlertaSolicitacao = msg;
                                }
                            } else {
                                tipoAlertaSolicitacao = 'error';
                                tituloAlertaSolicitacao = strings.erro;
                                mensagemAlertaSolicitacao = msg;
                            }
                            // Post message
                            const mensagem = {
                                "codigo": "500",
                                "mensagem": msg
                            }
                            window.parent.postMessage(mensagem, '*');

                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: handleCloseAlerta
                            }]);
                        })
                        .finally(() => {
                            setSubmitting(false);
                            setTipoAlerta(tipoAlertaSolicitacao);
                            setTituloAlerta(tituloAlertaSolicitacao);
                            setMensagemAlerta(mensagemAlertaSolicitacao);
                            setMostrarAlerta(true);
                        });
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        submitCount,
                        setFieldValue
                    }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    {/* Dados do paciente */}
                                    <SttExpansionPanel
                                        title={strings.dadosPaciente}
                                        opened={secaoPacienteAberta}
                                        callback={estadoInterno => abrirSecaoPaciente(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoPaciente}></div>
                                                <Paciente 
                                                    strings={strings}
                                                    headers={getHeaders()} 
                                                    usarTipoContato
                                                    persistirParametrosBusca
                                                    cpf-padrao={cpfPadrao} 
                                                    campos={campos} 
                                                    imc
                                                    formExterno={{
                                                        paciente: values[PACIENTE],
                                                        setFieldValue,
                                                        errors: errors[PACIENTE],
                                                        submitCount
                                                    }} 
                                                />

                                                {
                                                    touched.paciente && errors.paciente && errors.paciente.nome &&
                                                    <SttFormHelperText error>
                                                        {strings.pacienteObrigatorio}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Solicitante */}
                                    <SttExpansionPanel
                                        title={strings.solicitante}
                                        opened={secaoSolicitanteAberta}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecaoSolicitante(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Solicitante strings={strings} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Informações clínicas */}
                                    <SttExpansionPanel
                                        title={strings.infoClinicas}
                                        opened={secaoInfoClicnicasAberta}
                                        callback={estadoInterno => abrirSecaoInfoClinicas(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoInfoClicnicas}></div>
                                                <InformacoesClinicas strings={strings} />
                                            </Suspense>
                                        }
                                    />

                                    <Divider />
                                    {/* Informações complementares */}
                                    <SttExpansionPanel
                                        title={strings.imformacoesCompl}
                                        opened={secaoInfoComplementaresAberta}
                                        callback={estadoInterno => abrirSecaoInfoComplementares(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <InformacoesComplementares strings={strings} />
                                            </Suspense>
                                        }
                                    />

                                    <Divider />
                                    {/* Lesões */}
                                    <SttExpansionPanel
                                        title={strings.lesoes}
                                        opened={secaoLesoesAberta}
                                        callback={estadoInterno => abrirSecaoLesoes(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoLesoes}></div>
                                                <Lesoes strings={strings} lesoes={values.lesoes} />
                                                {
                                                    touched.lesoes && errors.lesoes &&
                                                    <SttFormHelperText error>
                                                        {errors.lesoes}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />

                                    {values.lesoes.some(lesao => lesao.tipo === TIPO_DERMATOSE.CANCER_PELE) &&
                                        <div>
                                            <Divider />
                                            {/* Protocolo para encaminhamento - Somente para câncer da pele */}
                                            <SttExpansionPanel
                                                title={strings.protocoloCancerPele}
                                                opened={secaoHmpAberta}
                                                callback={estadoInterno => abrirSecaoProtocoloEnc(estadoInterno)}
                                                children={
                                                    <div ref={secaoProtocoloEnc}>
                                                        <ProtocoloEncaminhamento strings={strings} />
                                                    </div>
                                                }
                                            />
                                        </div>
                                    }

                                    <Divider />
                                    {/* CID-10 */}
                                    <SttExpansionPanel
                                        title={strings.cid10}
                                        opened={secaoCid10Aberta}
                                        callback={estadoInterno => abrirSecaoCID10(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <SttCid10 
                                                    strings={strings}
                                                    headers={getHeaders()}
                                                    multiplos
                                                    formExterno={{
                                                        cid10: values.cid10,
                                                        isSubmitting,
                                                        errors,
                                                        submitCount,
                                                        setFieldValue
                                                    }}
                                                />
                                            </Suspense>
                                        }
                                    />

                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => {
                                            if (!errors) {
                                                return;
                                            }
                                            let node;
                                            if (errors[PACIENTE]) {
                                                abrirSecaoPaciente(true);
                                                node = secaoPaciente.current;
                                            } else if (errors[MEDICO_SOLICITANTE] || errors[INSTITUICAO_SOLICITANTE]) {
                                                abrirSecaoSolicitante(true);
                                                node = secaoSolicitante.current;
                                            } else if (errors[FIELDS.TABAGISMO] ||
                                                errors[FIELDS.TABAGISMO_CIGARROS_DIA] ||
                                                errors[FIELDS.TABAGISMO_ANOS_FUMO] ||
                                                errors[FIELDS.ETILISMO] ||
                                                errors[FIELDS.FOTOTIPO] ||
                                                errors[FIELDS.EXPOSICAO_SOL_HORAS_DIA] ||
                                                errors[FIELDS.EXPOSICAO_SOL_MESES_ANOS] ||
                                                errors[FIELDS.FOTOPROTETOR_FREQUENCIA] ||
                                                errors[FIELDS.FOTOPROTETOR_PERIODO]) {
                                                
                                                abrirSecaoInfoClinicas(true);
                                                node = secaoInfoClicnicas.current;

                                                if ((errors[FIELDS.EXPOSICAO_SOL_HORAS_DIA] ||
                                                    errors[FIELDS.EXPOSICAO_SOL_MESES_ANOS] ||
                                                    errors[FIELDS.FOTOPROTETOR_FREQUENCIA] ||
                                                    errors[FIELDS.FOTOPROTETOR_PERIODO]) && 
                                                    !errors[FIELDS.TABAGISMO] &&
                                                    !errors[FIELDS.TABAGISMO_CIGARROS_DIA] &&
                                                    !errors[FIELDS.TABAGISMO_ANOS_FUMO] &&
                                                    !errors[FIELDS.ETILISMO] &&
                                                    !errors[FIELDS.FOTOTIPO]) {

                                                    // Como essa seção é muito extensa, se houver erros nos último elementos, eles precisam
                                                    // ser mostrados no viewport. Para isso foram criados elementos vazios com identificadores
                                                    // em posições estratégicas de modo a localiar e exibir cada trecho do formulário de acordo
                                                    // com os erros que existem
                                                    const nodesFilhos = Array.from(secaoInfoClicnicas.current.nextElementSibling.childNodes);
                                                    const subNodes = nodesFilhos.filter(n => n.id);
                                                    
                                                    node = subNodes.shift();   
                                                }

                                            } else if (errors[LESOES]) {
                                                abrirSecaoLesoes(true);
                                                node = secaoLesoes.current;
                                            } else if (errors[HMP_CANCER_PELE] || errors[HMP_CANCER_OUTRO]) {
                                                abrirSecaoProtocoloEnc(true);
                                                node = secaoProtocoloEnc.current;
                                            }
                                            
                                            if (node) {
                                                setTimeout(() => {
                                                    node.scrollIntoView({
                                                        behavior: 'smooth',
                                                        block: 'center',
                                                        inline: 'start'
                                                    });
                                                }, 500);
                                            }
                                        }}
                                    >
                                        {strings.enviar}
                                    </SttButton>
                                    {
                                        origem === TIPO_USUARIO.EXTERNO &&
                                        <SttButton
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={handleCancelarEnvio}
                                        >
                                            {strings.cancelar}
                                        </SttButton>
                                    }
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                                <Alerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={handleCloseAlerta}
                                />
                                <Alerta
                                    open={confirmarTermo}
                                    title={strings.termoEsclarecimento}
                                    message={strings.mensagemConfirmarTermo_solicitacao}
                                    type={'confirmation'}
                                    options={[
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                setConfirmarTermo(false);
                                                setGerarTermo(true);
                                                if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                                    setConfirmarEnvioExame(true);
                                                }
                                            }
                                        },
                            
                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                setConfirmarTermo(false);
                                                if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                                    setConfirmarEnvioExame(true);
                                                }
                                            }
                                        }
                                    ]}
                                    onClose={() => {
                                        setConfirmarTermo(false);
                                        if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                            setConfirmarEnvioExame(true);
                                        }
                                    }}
                                />

                                {
                                    temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) && 
                                    <Alerta
                                        open={confirmarEnvioExame}
                                        title={strings.tituloConfirmarEnvioExame}
                                        message={strings.mensagemConfirmarEnvioExame}
                                        type={'confirmation'}
                                        options={[
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    setEnviarImagens(true);
                                                    setConfirmarEnvioExame(false);
                                                    handleAbrirModalEnvioImagens();
                                                }
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setSolicitacoes(false);
                                                    setConfirmarEnvioExame(false);
                                                }
                                            }
                                        ]}
                                        onClose={() => {
                                            setSolicitacoes(false);
                                            setConfirmarEnvioExame(false);
                                        }}
                                    />
                                }
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            {
                temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) && solicitacoes && enviarImagens &&
                <EnvioImagens 
                    open={mostrarModalEnvioImagens} 
                    resetFormulario={() => {
                        setSolicitacoes(false);
                        setMostrarModalEnvioImagens(false);
                        setEnviarImagens(false);
                    }}
                    handleCancelarEnvio={handleCancelarEnvio}
                    callbackFinalizarEnvio={() => {
                        if (origem === TIPO_USUARIO.EXTERNO) {
                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: logout
                            }]);
                            setTipoAlerta('success');
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(strings.exameRegistrado);
                            setMostrarAlerta(true);
                        }
                    }}
                    solicitacoes={solicitacoes} 
                />
            }
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutDispatch: () => dispatch(logoutAction())
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idSolicitacaoSistemaOrigem: state.index.idSolicitacaoSistemaOrigem,
        idSistemaOrigem: state.index.idSistemaOrigem,
        cpfPadrao: state.index.cpfPaciente
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Solicitacao')(Solicitacao));